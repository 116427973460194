import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueAnimateOnScroll from "vue3-animate-onscroll";

import "./assets/css/desktop.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";
import "./assets/css/animate.css";

const app = createApp(App);

app.use(router);
app.use(VueAnimateOnScroll);
app.mount("#app");
