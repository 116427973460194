<script setup>
import WhatsappIcon from "vue-material-design-icons/Whatsapp";

const openWhatsapp = () => {
  window.innerWidth > 719
    ? window.open(
        `https://web.whatsapp.com/send?phone=5511917070303&text=Olá! Estou no Festeje aí. Podem me ajudar?`
      )
    : window.open(
        `https://api.whatsapp.com/send?phone=5511917070303&text=Olá! Estou no Festeje aí. Podem me ajudar?`
      );
};
</script>

<template>
  <header>
    <div class="container">
      <img
        class="animated fadeInDown"
        src="@/assets/images/logo.png"
        alt="logo"
      />
      <div @click="openWhatsapp" class="btn animated fadeInDown">
        <span>Fale conosco</span>
      </div>
    </div>
  </header>
  <div class="whats-item" @click="openWhatsapp" target="_blank">
    <WhatsappIcon fillColor="#fff" />
  </div>
</template>

<style lang="scss" scoped>
.whats-item {
  position: fixed;
  width: 48px;
  height: 48px;
  background-color: var(--green1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2%;
  bottom: 20px;
  z-index: 99;
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  svg {
    color: #f0f0f0;
    font-size: 2em;
    pointer-events: none;
  }
}

header {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 0;
  z-index: 2;
  background-color: #f5f5f5;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 720px) {
      img {
        width: 140px;
      }
    }
  }
}

.btn {
  background-color: #4aca29;
  margin: 0;
  padding: 10px 30px;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #40af24;
  }
  span {
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;
  }
}
</style>
