<script setup>
import { ref } from "vue";

import Header from "@/components/Header";

import CheckIcon from "vue-material-design-icons/Check";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight";
import DownArrowIcon from "vue-material-design-icons/ChevronDown.vue";

const videos = ref([
  {
    title: "O que o Festeje aí faz?",
    video: require("../../public/videos/plataforma-geral.mp4"),
    image: require("@/assets/images/video1.png"),
  },
  {
    title: "Quais são os benefícios para os fornecedores?",
    video: require("../../public/videos/visao-fornecedor.mp4"),
    image: require("@/assets/images/video2.png"),
  },
  {
    title: "E para os clientes?",
    video: require("../../public/videos/visao-cliente.mp4"),
    image: require("@/assets/images/video3.png"),
  },
]);

const faq = ref([
  {
    id: "faq1",
    showAnswer: false,
    question: "Quais são os benefícios do cadastro de fornecedor?",
    answer:
      "<ul><li style='list-style: decimal'><b>Sem custo de investimento inicial:</b> Para ter acesso a utilizar a Plataforma Festeje aí, não é necessário investimento financeiro, basta acessar os links para cadastramento, configurar sua empresa e ser mostrar para o mundo.</li><li style='list-style: decimal'><b>Maior visibilidade da marca, produtos e serviços:</b> Ao se cadastrar na Plataforma Festeje aí, você terá a oportunidade de aumentar sua visibilidade junto ao público-alvo. As pessoas que estão planejando uma festa poderão encontrar seu negócio mais facilmente, o que pode resultar em um aumento nas reservas e nas vendas.</li><li style='list-style: decimal'><b>Aumento no número de eventos fechados:</b> Com a exposição adicional proporcionada pela Plataforma Festeje aí, você terá acesso a uma base de clientes mais ampla. Dessa forma, você poderá alcançar pessoas que talvez não estivessem cientes da existência do seu negócio anteriormente.</li><li style='list-style: decimal'><b>Avaliações e feedback dos clientes:</b> A Plataforma Festeje aí permite que os clientes avaliem e deixem feedback sobre os fornecedores com os quais tiveram experiências. Isso pode ser extremamente valioso para a construção de sua reputação online. Avaliações positivas e comentários elogiosos podem atrair novos clientes e aumentar a confiança dos consumidores em relação ao seu negócio.</li><li style='list-style: decimal'><b>Promoção efetiva:</b> Através de um serviço pago, a Plataforma Festeje aí irá oferecer recursos de promoção e publicidade para os fornecedores cadastrados que tiverem interesse em se destacar a visibilidade de seus produtos e serviços de forma estratégica na Plataforma, através de listas de fornecedores, banners promocionais, compartilhamento em redes sociais e outras estratégias de marketing digital. Essas promoções podem ajudar a impulsionar seu negócio e atrair mais clientes.</li></ul>",
  },
  {
    id: "faq2",
    showAnswer: false,
    question: "A plataforma já está disponível?",
    answer:
      "A partir de agosto de 2023, a Plataforma Festeje aí estará disponível para os fornecedores cadastrarem seus produtos e serviços relacionados ao ecossistema de festas. E posteriormente a plataforma será aberta para os clientes com através de uma mega campanha de marketing e divulgação.",
  },
  {
    id: "faq3",
    showAnswer: false,
    question: "Há algum custo para utilizar a ferramenta?",
    answer:
      "A Plataforma oferece um plano totalmente grátis para os fornecedores cadastrados. Além do plano gratuito, posteriormente será oferecido também um plano pago contendo vários benefícios adicionais.",
  },
  {
    id: "faq4",
    showAnswer: false,
    question: "Posso divulgar a plataforma para outras pessoas?",
    answer:
      "Sim! A partir de agosto de 2023 a plataforma será disponibilizada para todos os fornecedores dos segmentos eventos e festas se cadastrarem.",
  },
  {
    id: "faq5",
    showAnswer: false,
    question: "Tenho outra dúvida, posso falar com alguém?",
    answer: "Sim!! Através do e-mail marketing@festeje.ai",
  },
]);

const faqToggle = (item) => {
  const faqItem = document.querySelector(`#faq${item.id}`);
  const height = faqItem.querySelector(".body div").offsetHeight;

  item.showAnswer = !item.showAnswer;
  faqItem.querySelector(".body").style.height = item.showAnswer
    ? `${height}px`
    : "0px";
};
</script>

<template>
  <div>
    <Header />
    <section class="banner py-2">
      <img
        class="ellipse animated fadeInLeft mobile-hidden"
        style="animation-delay: 400ms"
        src="@/assets/images/ellipse.svg"
        alt="ellipse"
      />
      <div class="container">
        <div
          class="left-holder animated fadeInLeft mobile-hidden"
          style="animation-delay: 450ms"
        >
          <img src="@/assets/images/girl.png" alt="girl" />
        </div>
        <div class="right-holder">
          <h1 class="animated zoomIn" style="animation-delay: 600ms">
            Fornecedor receba acesso antecipado a mais nova plataforma de
            eventos do Brasil
          </h1>
          <span class="animated zoomIn" style="animation-delay: 650ms">
            Faça parte da plataforma festeje aí que oferece uma oportunidade
            única para expandir seus negócios, alcançar novos clientes e
            aumentar sua visibilidade no mercado de festas e eventos. Ao se
            juntar a nós, você terá acesso a uma rede diversificada de clientes
            em busca de fornecedores confiáveis e serviços de qualidade.
          </span>
          <a
            href="https://prestador.festeje.ai/#/login"
            target="_blank"
            class="btn animated fadeInUp"
            style="animation-delay: 700ms"
          >
            <span>CADASTRE-SE AGORA</span>
          </a>
        </div>
      </div>
    </section>
    <section class="why-register py-3">
      <div class="container">
        <h1 class="title animated fadeIn" style="animation-delay: 450ms">
          Por que me cadastrar agora?
        </h1>
        <p class="subtitle animated fadeIn" style="animation-delay: 500ms">
          Fazendo o cadastro agora, sua empresa do ramo de eventos terá
          vantagens em nossa plataforma. Confira alguns benefícios:
        </p>
        <div class="cards-holder">
          <div
            class="card"
            v-animate-onscroll="'animated fadeInLeft'"
            style="animation-delay: 400ms"
          >
            <img
              class="border-first"
              src="@/assets/images/people1.jpg"
              alt="people"
            />
            <span class="subtitle">Exposição privilegiada</span>
            <span>
              Os 50 primeiros fornecedores que se cadastrarem no Festeje aí
              ganharão <b>anúncios grátis por 60 dias</b> a partir do lançamento
              da plataforma.
            </span>
          </div>
          <div
            class="card"
            v-animate-onscroll="'animated fadeInUp'"
            style="animation-delay: 500ms"
          >
            <img
              class="border-second"
              src="@/assets/images/people2.jpg"
              alt="people"
            />
            <span class="subtitle">Divulgação em redes sociais</span>
            <span>
              Os 25 primeiros fornecedores cadastrados terão seus serviços
              anunciados em nossas <b>redes sociais</b> através de um programa
              de marketing conduzido pelo Festeje aí
            </span>
          </div>
          <div
            class="card"
            v-animate-onscroll="'animated fadeInRight'"
            style="animation-delay: 600ms"
          >
            <img
              class="border-third"
              src="@/assets/images/people3.jpg"
              alt="people"
            />
            <span class="subtitle">Vantagem competitiva</span>
            <span>
              Ao serem os primeiros a se cadastrarem, os fornecedores ganharão
              <b>seções de destaque na plataforma</b>, com maior visibilidade
              para os usuários.
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="videos py-3">
      <div class="container">
        <h1
          class="title"
          v-animate-onscroll="'animated fadeIn'"
          style="animation-delay: 200ms"
        >
          Como a plataforma funciona
        </h1>
        <div class="video-holder">
          <div
            class="video-item"
            v-for="(item, index) in videos"
            :key="index"
            v-animate-onscroll="'animated zoomIn'"
          >
            <span>{{ item.title }}</span>
            <video class="video" controls :poster="item.image">
              <source :src="item.video" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>
    <section class="faq py-3">
      <div class="container">
        <div class="left-holder" v-animate-onscroll="'animated fadeInLeft'">
          <h1 class="title">
            Quer saber mais sobre o
            <span class="text-tag">Festeje aí</span>
            ?
          </h1>
          <span class="description">
            As dúvidas mais frequentes sobre a plataforma respondidas para você.
            Navegue pelas questões ou entre em contato com nossa equipe!
          </span>
        </div>
        <div class="right-holder">
          <div
            v-for="item in faq"
            :key="item.id"
            :id="`faq${item.id}`"
            class="faq-item"
            v-animate-onscroll="'animated zoomIn'"
          >
            <div class="header" @click="faqToggle(item)">
              <p
                :style="[item.showAnswer ? { 'font-family': 'fontBold' } : '']"
              >
                {{ item.question }}
              </p>
              <DownArrowIcon
                :class="{ opened: item.showAnswer }"
                style="transition: transform 0.25s ease"
              />
            </div>
            <div class="body">
              <div class="faq-answer" v-html="item.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="first py-3">
      <div class="container">
        <div
          class="left-holder"
          v-animate-onscroll="'animated fadeInLeft'"
          style="animation-delay: 200ms"
        >
          <h1 class="title">Preencha sua agenda de eventos no Festeje aí</h1>
          <span class="description">
            A melhor plataforma para a conexão de fornecedores e clientes está
            chegando no mercado! Faça o pré-cadastro da sua empresa e receba
            condições especiais no lançamento.
          </span>
          <a
            href="https://prestador.festeje.ai/#/login"
            target="_blank"
            class="btn"
          >
            <span>FAZER CADASTRO</span>
          </a>
        </div>
        <div
          class="right-holder"
          v-animate-onscroll="'animated fadeInRight'"
          style="animation-delay: 300ms"
        >
          <ul>
            <li>
              <div class="check"><CheckIcon /></div>
              <span>Receba solicitações de orçamentos</span>
            </li>
            <li>
              <div class="check"><CheckIcon /></div>
              <span>Apresente os seus produtos e serviços</span>
            </li>
            <li>
              <div class="check"><CheckIcon /></div>
              <span>Clientes de todos os nichos de eventos</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="second py-3">
      <div class="container">
        <h1
          class="title"
          v-animate-onscroll="'animated fadeIn'"
          style="animation-delay: 500ms"
        >
          Por que escolher o Festeje aí?
        </h1>
        <div class="cards-holder">
          <div
            class="card"
            v-animate-onscroll="'animated fadeInLeft'"
            style="animation-delay: 600ms"
          >
            <img src="../assets/images/icons/user-search.png" alt="icone" />
            <span class="subtitle">Seja encontrado</span>
            <span>
              Nosso sistema de busca permite que a sua empresa seja visualizada
              por usuários interessados nas suas soluções e na sua região de
              atendimento.
            </span>
          </div>
          <div
            class="card"
            v-animate-onscroll="'animated fadeInUp'"
            style="animation-delay: 700ms"
          >
            <img src="../assets/images/icons/money.png" alt="icone" />
            <span class="subtitle">Comissões competitivas</span>
            <span>
              Fature com clientes no Festeje aí com a menor taxa de serviço do
              mercado. O faturamento do seu negócio é todo seu. Simples assim.
            </span>
          </div>
          <div
            class="card"
            v-animate-onscroll="'animated fadeInRight'"
            style="animation-delay: 800ms"
          >
            <img src="../assets/images/icons/balloon.png" alt="icone" />
            <span class="subtitle">Sem restrições</span>
            <span>
              Aumente seu faturamento de forma significativa escalando seus
              negócios e apresentando sua empresa para milhares de clientes.
            </span>
          </div>
        </div>
      </div>
    </section>

    <section class="third py-3">
      <div class="container">
        <div class="left-holder" v-animate-onscroll="'animated fadeInDown'">
          <h1 class="title">O melhor suporte e gerenciamento do seu negócio</h1>
          <ul>
            <li>
              <div class="chevron"><ChevronRightIcon :size="20" /></div>
              <span class="description">
                Oferecemos um sistema de vitrine inovador e que destaca os
                aspectos mais valiosos das suas soluções.
              </span>
            </li>
            <li>
              <div class="chevron"><ChevronRightIcon :size="20" /></div>
              <span class="description">
                Gerencie os seus pedidos de orçamento, pagamentos, anúncios,
                clientes em um ambiente de uso seguro e intuitivo.
              </span>
            </li>
            <li>
              <div class="chevron"><ChevronRightIcon :size="20" /></div>
              <span class="description">
                Nosso suporte conta com atendimento técnico e apoio para
                alavancar os seus resultados no Festeje aí.
              </span>
            </li>
          </ul>
        </div>
        <div class="right-holder" v-animate-onscroll="'animated fadeInUp'">
          <img src="../assets/images/devices.png" alt="aparelhos" />
        </div>
      </div>
    </section>

    <section class="fifth py-3">
      <div class="container">
        <h1
          class="title"
          v-animate-onscroll="'animated fadeInDown'"
          style="animation-delay: 200ms"
        >
          Junte-se ao Festeje aí hoje
        </h1>
        <div class="itens-holder">
          <ul>
            <li
              v-animate-onscroll="'animated fadeIn'"
              style="animation-delay: 300ms"
            >
              <div class="check"><CheckIcon fillColor="#fff" /></div>
              <span>Apareça no topo da listagem de fornecedores</span>
            </li>
            <li
              v-animate-onscroll="'animated fadeIn'"
              style="animation-delay: 350ms"
            >
              <div class="check"><CheckIcon fillColor="#fff" /></div>
              <span>Tenha uma equipe de suporte dedicada a sua empresa</span>
            </li>
            <li
              v-animate-onscroll="'animated fadeIn'"
              style="animation-delay: 400ms"
            >
              <div class="check"><CheckIcon fillColor="#fff" /></div>
              <span>Divulgações especiais no lançamento</span>
            </li>
          </ul>
        </div>
        <a
          href="https://prestador.festeje.ai/#/login"
          target="_blank"
          class="btn"
          v-animate-onscroll="'animated fadeInUp'"
          style="animation-delay: 700ms"
        >
          <span>ENVIAR INSCRIÇÃO</span>
        </a>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
section.banner {
  position: relative;
  margin: 0 auto;
  background-color: var(--primary);
  padding-bottom: 0px !important;
  @media only screen and (max-width: 1139px) {
    padding-bottom: 36px !important;
  }
  .ellipse {
    position: absolute;
    top: -110px;
    left: 0;
    width: 25%;
    @media only screen and (min-width: 721px) and (max-width: 1139px) {
      top: -90px;
      width: 30%;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left-holder {
    position: relative;
    display: flex;
    width: 70%;
    @media only screen and (min-width: 721px) and (max-width: 1139px) {
      position: absolute;
      bottom: -36px;
      width: 50%;
    }
    img {
      position: relative;
      width: 100%;
      object-fit: cover;
    }
  }
  .right-holder {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    @media only screen and (min-width: 721px) and (max-width: 1139px) {
      width: 55%;
      margin-left: auto;
    }

    @media only screen and (max-width: 720px) {
      width: 100%;
      align-items: center;
      text-align: center;
    }
    h1 {
      font-family: fontMedium;
      color: #fff;
      font-size: 1.8rem;
    }
    span {
      color: #fff;
    }
    .btn {
      margin: 0;
      margin: 20px 0 0 0;
      border-radius: 50px;
      span {
        color: var(--primary);
        font-family: fontBold;
      }
    }
  }
}

section.why-register {
  position: relative;
  margin: 0 auto;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 1.8rem;
    font-family: fontMedium;
    text-align: center;
  }
  p {
    width: 50%;
    text-align: center;
    margin-top: 10px;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
  .cards-holder .card img {
    width: 120px;
  }
  .border-first {
    border-radius: 35px 0px 0px 0px;
  }
  .border-second {
    border-radius: 35px 35px 0px 0px;
  }
  .border-third {
    border-radius: 0px 35px 0px 0px;
  }
}

section.videos {
  position: relative;
  margin: 0 auto;
  background-color: var(--primary);
  .title {
    font-size: 1.8rem;
    font-family: fontMedium;
    text-align: center;
    color: #fff;
  }
  .video-holder {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: space-between;
    gap: 35px;
    align-items: end;
    .video-item {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 35px;
      span {
        color: #fff;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    .video {
      width: 100%;
      aspect-ratio: 16/9;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}

section.faq {
  position: relative;
  margin: 0 auto;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: 720px) {
      flex-direction: column;
      gap: 30px;
    }
  }
  .left-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    gap: 15px;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
    .title {
      font-size: 1.8rem;
      font-family: fontMedium;
      .text-tag {
        position: relative;
        display: inline;
        font-size: 1.8rem;
        box-shadow: 5px 0 0 0 var(--secondary), -5px 0 0 0 var(--secondary);
        background: var(--secondary);
      }
    }
  }
  .right-holder {
    position: relative;
    width: 45%;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
    .faq-item {
      position: relative;
      width: 100%;
      background-color: rgba(239, 194, 7, 0.69);
      border-bottom: 1px solid var(--primary);
      .header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        p {
          font-family: fontMedium;
          font-size: 1rem;
          color: var(--primary);
          @media screen and (max-width: 720px) {
            font-size: 1.1rem;
          }
        }
        .opened {
          transform: rotate(180deg);
        }
      }
      .body {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 0;
        transition: height 0.3s ease;
        .faq-answer {
          position: relative;
          font-size: 0.9rem;
          line-height: 1.5rem;
          padding: 0px 24px 16px 24px;
        }
      }
    }
  }
}

section.first {
  position: relative;
  margin: 0 auto;
  background-image: url("../assets/images/balloon.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1139px) {
      flex-direction: column;
      gap: 30px;
      .left-holder,
      .right-holder,
      .btn {
        width: 100%;
      }
    }
  }
  .left-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    gap: 15px;
    h1,
    span {
      color: #fff;
    }
    .title {
      font-size: 1.8rem;
      font-family: fontMedium;
    }
    .btn {
      margin: 20px 0 0 0;
      border-radius: 50px;
      span {
        color: var(--primary);
        font-family: fontBold;
      }
    }
  }
  .right-holder {
    position: relative;
    padding: 2rem 1.8rem;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.35);
    width: fit-content;
    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        gap: 15px;
        span {
          color: #fff;
        }
        .check {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--secondary);
        }
      }
    }
  }
}

section.second {
  position: relative;
  margin: 0 auto;
  .title {
    font-size: 1.8rem;
    font-family: fontMedium;
    text-align: center;
  }
}

.cards-holder {
  position: relative;
  width: 100%;
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: space-between;
  gap: 35px 65px;
  align-items: flex-start;
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    .subtitle {
      font-family: fontMedium;
      font-size: 1.2rem;
    }
    img {
      width: 80px;
      object-fit: cover;
    }
  }
}

section.third {
  position: relative;
  margin: 0 auto;
  background-color: var(--primary);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1139px) {
      flex-direction: column;
      gap: 30px;
      .left-holder,
      .right-holder {
        width: 100%;
      }
    }
  }
  .left-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 50%;
    .title {
      font-size: 1.8rem;
      font-family: fontMedium;
      color: #fff;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: flex-start;
        gap: 15px;
        .description {
          color: #fff;
          width: 90%;
        }
        .chevron {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--secondary);
        }
      }
    }
  }
  .right-holder {
    position: relative;
    width: 50%;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

section.fourth {
  position: relative;
  margin: 0 auto;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1139px) {
      flex-direction: column;
      gap: 30px;
      .left-holder,
      .right-holder,
      .btn {
        width: 100% !important;
      }
    }
  }
  .right-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
    gap: 20px;
    .title {
      font-size: 1.8rem;
      font-family: fontMedium;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      li {
        display: flex;
        align-items: flex-start;
        gap: 15px;
        .description {
          width: 90%;
        }
        .check {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--secondary);
        }
      }
    }
    .btn {
      margin: 0;
      border-radius: 50px;
      width: 50%;
      span {
        color: #fff;
        font-family: fontBold;
      }
    }
  }
  .left-holder {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

section.fifth {
  position: relative;
  margin: 0 auto;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 720px) {
      .btn {
        width: 100%;
        margin: 0;
      }
    }
  }
  .title {
    font-size: 1.8rem;
    font-family: fontMedium;
    text-align: center;
  }
  .itens-holder {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        gap: 15px;
        .check {
          position: relative;
          width: 36px;
          height: 36px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--secondary);
          @media only screen and (max-width: 720px) {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
  .btn {
    margin: 20px 0 0 0;
    border-radius: 50px;
    width: 50%;
    @media only screen and (max-width: 1139px) {
      width: 100%;
    }
    span {
      color: #fff;
      font-family: fontBold;
    }
  }
}

@media only screen and (max-width: 720px) {
  .animated {
    animation: none !important;
  }
}
</style>
